<!--  -->
<template>
  <div class="screenFromCard">
        <div class="from_wrap">
            <el-form ref="screenFromRef" :model="screenFrom" label-width="80px">
            <el-form-item label="创建时间" prop="createTime">
                <el-date-picker
                    size="small"
                    v-model="screenFrom.createTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>

            <div class="u-f-ac">
                <el-form-item label="配件名称" prop="goodsName">
                <el-input class="goodiput" size="small"
                    placeholder="请输入配件名称" clearable v-model="screenFrom.goodsName"></el-input>
                </el-form-item>
                <el-form-item style="margin-left: 16px; margin-right: 16px" label="配件编号" prop="goodsNum">
                    <el-input class="goodiput" size="small" placeholder="请输入配件编号" v-model="screenFrom.goodsNum"></el-input>
                </el-form-item>
            </div>
            <div class="u-f-ac">
                <el-form-item label="价格" prop="minPrice">
                    <div class="u-f-ac">
                        <el-input class="collect-price"
                            size="small" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="screenFrom.minPrice"></el-input>
                        <div class="yuan u-f-ajc">元</div>
                    </div>
                </el-form-item>
                <div class="line"></div>
                <el-form-item style="margin-left: -80px" prop="maxPrice">
                <div class="u-f-ac">
                    <el-input class="collect-price" size="small"
                        onkeyup="value=value.replace(/[^\d]/g,'')" v-model="screenFrom.maxPrice"></el-input>
                    <div class="yuan u-f-ajc">元</div>
                </div>
                </el-form-item>
            </div>
            <el-form-item>
                <el-button type="primary" size="small" style="width: 80px" @click="submitForm">筛选</el-button>
                <el-button type="info" size="small" style="width: 80px" @click="resetForm">重置</el-button>
            </el-form-item>
            </el-form>
        </div>
  </div>
</template>

<script>
import {dateToMs} from "@/methods/util";
export default {
    data () {
        return {
            screenFrom: {
                goodsName: '',
                goodsNum: '',
                startDate: '',
                endDate: '',
                createTime: null,
                minPrice: null,
                maxPrice: null,
                catalogId: null
            },
        };
    },
    methods: {
        submitForm() {
            if(this.screenFrom.createTime){
                this.screenFrom.startDate = dateToMs(this.screenFrom.createTime[0]);
                this.screenFrom.endDate = dateToMs(this.screenFrom.createTime[1]);
            }
            this.$emit('screenData',this.screenFrom)
        },
        resetForm() {
            this.$refs.screenFromRef.resetFields();
        },
    }
}

</script>
<style lang='scss'>
.screenFromCard{
    .from_wrap {
        margin-top: 10px;
        background-color: #f2f3f5;
        padding: 10px 0;
        .u-f-ac{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .goodiput {
            width: 212px;
        }
        .collect-price {
            width: 98px;
        }
        .line {
            margin: 0 4px;
            margin-bottom: 20px;
            height: 1px;
            width: 8px;
            background-color: rgb(170, 141, 141);
        }
        .yuan {
            width: 20px;
            height: 31px;
            text-align: center;
            line-height: 31px;
            border-radius: 4px;
            margin-left: -25px;
            padding: 0 2px;
            font-size: 12px;
            color: rgb(150, 150, 150);
            background-color: rgb(236, 232, 232);
            z-index: 200;
        }
    }
}
</style>